import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Ikke funnet" />
    <h1>Ikke funnet</h1>
    <p>Innholdet du leter etter ble ikke funnet.</p>
  </Layout>
)

export default NotFoundPage
